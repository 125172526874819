<template>
  <div>
    <div
      class="flex items-center justify-center"
      @click="showImageUploadModal()"
      :style="`
        min-height: ${maxHeight};
        min-width: ${maxWidth};
        max-width: ${maxWidth};
        max-height: ${maxHeight};
        cursor: pointer;
      `"
    >
      <img
        :style="`
        max-width: ${maxWidth};
        max-height: ${maxHeight};
        `"
        :src="!isEmpty(image) ? image : require('@/assets/images/picture-placeholder.png')"
        alt="LOGO"
      />
    </div>
    <vs-popup
      :title="$t('imagem')"
      :active.sync="showUploadPopup"
      style="overflow-y: auto; z-index: 54000;"
    >
      <div class="flex flex-wrap items-center">
        <vue-dropzone
          id="upload"
          ref="fileUploadZone"
          v-on:vdropzone-success="success"
          v-on:vdropzone-sending="sending"
          v-on:vdropzone-error="error"
          v-on:vdropzone-file-added="added"
          v-on:vdropzone-queue-complete="complete"
          :use-font-awesome="true"
          :options="dropzoneOptions"
          :use-custom-dropzone-options="true"
          :max_number_of_files="1"
          :useCustomSlot="true"
          style="width: 100%; margin-bottom: 10px"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              {{ $t('arraste-e-solte-para-enviar-a-imagem') }}
            </h3>
            <div class="subtitle">
              {{ $t('ou-selecione-uma-imagem-do-seu-computador') }}
            </div>
          </div>
        </vue-dropzone>
      </div>
    </vs-popup>
  </div>
</template>


<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import standard from '@/services/standard'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    uploadUrl: {
      type: String,
      default: `${process.env.VUE_APP_API_BASE_URL}/api/v1/medias/undefined/optimize_image`
    },
    image: {
      type: String,
      defaul: ''
    },
    maxWidth: {
      type: String,
      default: '100px'
    },
    maxHeight: {
      type: String,
      default: '100px'
    }
  },
  model: {
    prop: 'image',
    event: 'input'
  },
  data() {
    return {
      dropzoneOptions: {
        url: this.uploadUrl,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        timeout: 180000,
        headers: { Authorization: `Bearer ${standard.token()}` },
        acceptedFiles: 'image/jpeg,image/png'
      },
      showUploadPopup: false
    }
  },
  methods: {
    showImageUploadModal() {
      this.showUploadPopup = true
    },
    success(file) {
      // this.$refs.fileUploadZone.processQueue()
      // const response = JSON.parse(file.xhr.response)
      console.log(file.dataURL)
      this.setImage(file.dataURL)
      this.showUploadPopup = false
    },
    setImage(data) {
      if (data) {
        this.image = data
        this.$emit('input', data)
      }
    },
    sending(file, xhr, formData) {
      // formData.append('something', this.selectedOptionId)
    },
    error(file) {
      if (file.status === 'error') {
        this.notifyError(this.$vs, file.xhr.response)
        this.removeAllFiles()
      }
    },
    added(file) {
      file['key'] = this.getFilenameIndex(file.name)
    },
    complete(file, xhr, formData) {
      this.removeAllFiles()
      this.$emit('upload-complete')
    },
    removeAllFiles() {
      this.$refs.fileUploadZone.removeAllFiles()
    },
    getFilenameIndex(name) {
      const res = name.replace(' ', '_')
      return res.replace(/[^0-9a-zA-Z]/g, '')
    },
  }
}
</script>
