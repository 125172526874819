<template>
  <vx-card
    :title="id === null ? $t('organization_create') : $t('organization_edit')"
  >
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          :label="$t('name')"
          v-model="name"
          name="name"
          v-validate="'required|max:191'"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first('name')
        }}</span>
      </div>
    </div>

    <div class="vx-row" v-if="id">
      <div class="vx-col w-full">
        <label class="w-full text-left block"> Custom data: </label>
        
        <vs-textarea
          ref="textarea"
          class="w-full"
          v-model="customData"
          @input="customDataChange"/>

        <span class="text-danger text-sm" v-show="errors.has('customData')">{{
          errors.first('customData')
        }}</span>
      </div>

    </div>

    <div class="vx-row justify-center" v-if="id">
      <div class="vx-col">
        <label class="w-full text-left block"> Logo: </label>
        <image-upload
          class="border-faint-grey border-solid border p-2 rounded-lg"
          maxHeight="100px"
          maxWidth="200px"
          v-model="imageData"
          :key="logoUploadURL"
          :uploadUrl="logoUploadURL"
          @upload-complete="uploadLogoSuccess"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="float-right"
          color="primary"
          type="border"
          @click="cancel"
          >{{ $t('action.cancel') }}</vs-button
        >
        <vs-button
          v-permission="'organizations.create'"
          class="float-right mr-2"
          @click="create"
          >{{ $t('action.save') }}</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import OrganizationService from '@/services/api/OrganizationService'
import ImageUpload from '@/components/ImageUpload.vue'

export default {
  components: { ImageUpload },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    id: null,
    name: '',
    service: null,
    imageData: '',
    customData: null,
    validCustomData: true
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.name)
    },
    logoUploadURL() {
      const base = `${process.env.VUE_APP_API_BASE_URL}/api/v1`
      return `${base}/organizations/${this.id}/logo`
    }
  },
  methods: {
    uploadLogoSuccess() {
      this.notifySuccess(this.$vs, this.$t('logo-atualizada-com-sucesso-para-refletir-as-alteracoes-entre-novamente-na-plataforma-ou-aperte-ctrl-f5'), 10000)
    },
    create() {

      if (!this.isEmpty(this.customData)) {
        try {
          const cd = JSON.parse(this.customData)

        } catch (error) {
          this.notifyWarning(this.$vs, 'Valor customizado deve ser um JSON válido, adicione aspas nas chaves e valores!')
          return
        }
      }

      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdate({
          id: this.id,
          name: this.name,
          custom_data: this.customData
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/organizations/${data.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    cancel() {
      this.$router.push('/organizations')
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.name = data.name
          this.imageData = this._.get(data, 'logo')
          this.customData = data.custom_data
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    }
  },
  beforeMount() {
    this.service = OrganizationService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
  }
}
</script>
