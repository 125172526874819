var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null
            ? _vm.$t("organization_create")
            : _vm.$t("organization_edit"),
      },
    },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:191",
                  expression: "'required|max:191'",
                },
              ],
              staticClass: "w-full",
              attrs: { label: _vm.$t("name"), name: "name" },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("name"),
                    expression: "errors.has('name')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("name")))]
            ),
          ],
          1
        ),
      ]),
      _vm.id
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "w-full text-left block" }, [
                  _vm._v(" Custom data: "),
                ]),
                _c("vs-textarea", {
                  ref: "textarea",
                  staticClass: "w-full",
                  on: { input: _vm.customDataChange },
                  model: {
                    value: _vm.customData,
                    callback: function ($$v) {
                      _vm.customData = $$v
                    },
                    expression: "customData",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("customData"),
                        expression: "errors.has('customData')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("customData")))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.id
        ? _c("div", { staticClass: "vx-row justify-center" }, [
            _c(
              "div",
              { staticClass: "vx-col" },
              [
                _c("label", { staticClass: "w-full text-left block" }, [
                  _vm._v(" Logo: "),
                ]),
                _c("image-upload", {
                  key: _vm.logoUploadURL,
                  staticClass:
                    "border-faint-grey border-solid border p-2 rounded-lg",
                  attrs: {
                    maxHeight: "100px",
                    maxWidth: "200px",
                    uploadUrl: _vm.logoUploadURL,
                  },
                  on: { "upload-complete": _vm.uploadLogoSuccess },
                  model: {
                    value: _vm.imageData,
                    callback: function ($$v) {
                      _vm.imageData = $$v
                    },
                    expression: "imageData",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { color: "primary", type: "border" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("action.cancel")))]
            ),
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "organizations.create",
                    expression: "'organizations.create'",
                  },
                ],
                staticClass: "float-right mr-2",
                on: { click: _vm.create },
              },
              [_vm._v(_vm._s(_vm.$t("action.save")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }