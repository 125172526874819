var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "flex items-center justify-center",
          style:
            "\n      min-height: " +
            _vm.maxHeight +
            ";\n      min-width: " +
            _vm.maxWidth +
            ";\n      max-width: " +
            _vm.maxWidth +
            ";\n      max-height: " +
            _vm.maxHeight +
            ";\n      cursor: pointer;\n    ",
          on: {
            click: function ($event) {
              return _vm.showImageUploadModal()
            },
          },
        },
        [
          _c("img", {
            style:
              "\n      max-width: " +
              _vm.maxWidth +
              ";\n      max-height: " +
              _vm.maxHeight +
              ";\n      ",
            attrs: {
              src: !_vm.isEmpty(_vm.image)
                ? _vm.image
                : require("@/assets/images/picture-placeholder.png"),
              alt: "LOGO",
            },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: { title: _vm.$t("imagem"), active: _vm.showUploadPopup },
          on: {
            "update:active": function ($event) {
              _vm.showUploadPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c(
                "vue-dropzone",
                {
                  ref: "fileUploadZone",
                  staticStyle: { width: "100%", "margin-bottom": "10px" },
                  attrs: {
                    id: "upload",
                    "use-font-awesome": true,
                    options: _vm.dropzoneOptions,
                    "use-custom-dropzone-options": true,
                    max_number_of_files: 1,
                    useCustomSlot: true,
                  },
                  on: {
                    "vdropzone-success": _vm.success,
                    "vdropzone-sending": _vm.sending,
                    "vdropzone-error": _vm.error,
                    "vdropzone-file-added": _vm.added,
                    "vdropzone-queue-complete": _vm.complete,
                  },
                },
                [
                  _c("div", { staticClass: "dropzone-custom-content" }, [
                    _c("h3", { staticClass: "dropzone-custom-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("arraste-e-solte-para-enviar-a-imagem")
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("ou-selecione-uma-imagem-do-seu-computador")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }